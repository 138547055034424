import React from "react";
import { Button, MDBInput } from "mdbreact";
import ApiService from "../../../services/ApiService";
import GuestsEditor from "./GuestsEditor";
import QuickGuestsEditor from "./QuickGuestsEditor";
import ExportGuests from "./ExportGuests";
import InvoicesModal from "./InvoicesModal";
import ParticipationValidation from "./ParticipationValidation";
import GuestPage from "../../../common/GuestPage";
// import Passport from "../../../common/Passport";
import Invoice from "../../../common/Invoice";
import TransacEmailContainer from "../../../common/TransacEmailContainer";
import CheckinMessage from "./CheckinMessage";
import Loader from "../../../common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  faEraser,
  faEdit,
  faAt,
  faIdCard,
  faCheck,
  faChevronCircleDown,
  faChevronCircleRight,
  faPaperclip,
  faEye,
  faTimes,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../common/Pagination";
import { Link } from "react-router-dom";
import "./GuestsContainer.css";
import {
  displayFollowers,
  checkOne,
  responsesOptions,
  documentsOptions,
  makeUniqArrayByKey,
  isEventPaymentConfigOk,
  sortByLastName,
} from "../../../services/Utils";
import UploadFileModal from "./UploadFileModal";
import EmailPreviewModal from "../../../common/EmailPreviewModal";
import { languagesList } from "../../../common/Translations";
import { hasGuestBeenChecked } from "../../../checkin/new/utils";

function Showaccompagne({ followers, guest }) {
  const followerslength = followers.filter(
    (follower) => follower.invited_by.contact_id === guest.contact_id
  ).length;
  const max = guest.max_invitation;

  return followerslength + "/" + max;
}
class GuestsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.checkAllRef = React.createRef(null);
    this.filter = this.filter.bind(this);
    this.loadData = this.loadData.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editGuests = this.editGuests.bind(this);
    this.deleteGuestResponse = this.deleteGuestResponse.bind(this);
    this.downLoadInvoice = this.downLoadInvoice.bind(this);
    this.filterGuests = this.filterGuests.bind(this);
    this.generateTableReponse = this.generateTableReponse.bind(this);
    this.filterByTag = this.filterByTag.bind(this);
    this.changePage = this.changePage.bind(this);
    this.updateContact = this.updateContact.bind(this);
    this.setGuests = this.setGuests.bind(this);
    this.openModalUploadFile = this.openModalUploadFile.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.defaultValueEdited = this.defaultPackageGuest.bind(this);
    this.defaultMaxInvitationGuest = this.defaultMaxInvitationGuest.bind(this);
    this.defaultPackageFollowers = this.defaultPackageFollowers.bind(this);
    this.downLoadPassport = this.downLoadPassport.bind(this);
    this.passeportZipGeneration = this.passeportZipGeneration.bind(this);
    this.updateOneGuest = this.updateOneGuest.bind(this);
    this.sortInvoiceNumbers = this.sortInvoiceNumbers.bind(this);
    this.state = {
      delegatairesList: [],
      followersDisplayed: [],
      reponses: [],
      currentPage: 0,
      numberPerPage: 15,
      totalPages: 0,
      guests: [],
      tagsFilter: "",
      guestsFiltered: [],
      guestsChecked: [],
      followers: [],
      nameFilter: "",
      reponseFilter: "",
      packageFilter: "",
      delegataireFilter: "",
      documentFilter: "",
      companyFilter: "",
      langueFilter: "",
      createdOnCheckinFilter: "",
      checkRefundRequests: "",
      tagsList: [],
      tagOptions: [],
      companiesList: [],
      packageId: "",
      invitation_package_id: "",
      packagesOptions: [],
      isLoading: true,
      action: "",
      max_invitation: 0,
      is_followers: false,
      pdfToRender: "",
      form: [],
      invoicesList: [],
      placementsList: [],
      participants: 0,
      totalGuests: 0,
      openModal: false,
      guestModal: "",
      openModalPreview: false,
      maxGuests: 50,
      followerFilter: "",
      followerMaxInvitationList: [],
      followerMaxInvitationOptions: [],
      maxFollowerFilter: 0,
      event: this.props.event,
      invoiceSetting: [],
      isPasseportLoading: false,
      checkPaymentModal: false,
      isEventEnded:
        Date.parse(this.props.event.end_date) - Date.now() > 0 ? false : true,
    };
  }
  changePage(page) {
    this.setState({ currentPage: page });
  }

  async deleteGuestResponse(guest) {
    this.setState({ isLoading: true, pdfToRender: "" });
    await ApiService.request(
      {
        ...guest,
        is_validated: true,
        participate: false,
        check_in_message: "",
      },
      "guests/" + guest.id,
      "put"
    );
    this.state.reponses.forEach(async (reponse) => {
      if (Number(reponse.guest_id) === Number(guest.id)) {
        await ApiService.request(
          {},
          "component_responses/" + reponse.id,
          "delete"
        );
      }
    });

    this.state.followers.forEach(async (follower) => {
      if (
        follower.invited_by &&
        Number(follower.invited_by.contact_id) === Number(guest.contact_id)
      ) {
        console.log("suppression d'un accompagnant");
        await ApiService.request({}, "guests/" + follower.id, "delete");
      }
    });

    this.state.guests.forEach(async (guestFollower) => {
      if (
        guestFollower.invited_by &&
        Number(guestFollower.invited_by.contact_id) === Number(guest.contact_id)
      ) {
        console.log("suppression d'un accompagnant");
        await ApiService.request({}, "guests/" + guestFollower.id, "delete");
      }
    });

    this.loadData();
  }

  // downLoadPassport(guestId, eventId) {
  //   this.setState({
  //     pdfToRender: <Passport guestId={guestId} hide={true} eventId={eventId} />,
  //   });
  // }

  async passeportZipGeneration(guestsIds) {
    try {
      this.setState({ isPasseportLoading: true });
      const { id } = this.props.event;
      const user = JSON.parse(localStorage.getItem("user"));

      await ApiService.awaitRequest(
        {
          evenement_id: id,
          guests_list: guestsIds,
          user_id: user.id,
          host_url: window.location.host,
          name: null,
        },
        "passeports_zip",
        "post"
      );
      toast.success(
        "La génération est en cours, vous recevrez un mail dans les minutes qui suivent"
      );
    } catch (error) {
      toast.error("Erreur lors du téléchargement");
      console.log(error);
    } finally {
      this.setState({ isPasseportLoading: false });
    }
  }

  downLoadPassport = async (guest, selectedEvent) => {
    try {
      this.setState({ isPasseportLoading: true });

      const response = await ApiService.awaitRequest(
        {},
        `guest/${guest.id}/passeport/byevenement/${selectedEvent.id}`,
        "get",
        "",
        "",
        "blob"
      );

      const url = window.URL.createObjectURL(
        new Blob([response], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${selectedEvent.data.passportName}_${guest.lastname}_${guest.firstname}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      toast.success("Document téléchargé");
    } catch (error) {
      toast.error("Erreur lors du téléchargement");
      console.log(error);
    } finally {
      this.setState({ isPasseportLoading: false });
    }
  };

  downLoadInvoice(guestId, eventId, invoice) {
    this.setState({
      pdfToRender: (
        <Invoice invoice={invoice} guestId={guestId} eventId={eventId} />
      ),
    });
  }

  checkAll(event) {
    const guestsChecked = this.state.guestsFiltered.filter(
      (guest) =>
        !guest.invited_by &&
        (!guest.created_on_checkin ||
          guest.created_on_checkin ||
          (guest.user_id && guest.participate))
    );
    if (event.target.checked) {
      this.setState({
        guestsChecked,
        packageId: this.defaultPackageGuest(guestsChecked),
        max_invitation: this.defaultMaxInvitationGuest(guestsChecked),
        invitation_package_id: this.defaultPackageFollowers(guestsChecked),
      });
    } else {
      this.setState({
        guestsChecked: [],
        packageId: this.defaultPackageGuest(guestsChecked),
        max_invitation: this.defaultMaxInvitationGuest(guestsChecked),
        invitation_package_id: this.defaultPackageFollowers(guestsChecked),
      });
    }
  }

  editGuests() {
    const self = this;
    const { guestsChecked, packageId, invitation_package_id, max_invitation } =
      this.state;

    this.setState({ isLoading: true, pdfToRender: "" }, async () => {
      await ApiService.request(
        {
          guests_ids: guestsChecked.map((guest) => guest.id),
          package_id: packageId,
          invitation_package_id:
            max_invitation > 0
              ? isNaN(invitation_package_id)
                ? ""
                : invitation_package_id
              : "",
          max_invitation: max_invitation,
        },
        "guests/update/bygroup",
        "put"
      );

      self.loadData();
    });
  }

  updateContact(contact) {
    const contacts = this.props.contacts;
    contacts.push({ ...contact });

    this.props.updateContacts(contacts);
    this.loadData();
  }

  async loadData(hideLoader) {
    const self = this;

    this.setState({ isLoading: !hideLoader, pdfToRender: "" });

    const event = await ApiService.awaitRequest(
      this.state,
      "evenements/" + this.props.match.params.eventid,
      "get"
    );

    await ApiService.request(
      {},
      "invoices/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      // console.log(data, "console.log")
      self.setState({
        invoicesList: data,
      });
    });
    if (this.state.form) {
      await ApiService.request(
        {},
        "component_responses/byevenement/" + this.state.form.id,
        "get"
      ).then(function (data) {
        const componentEntrance = self.props.components.filter(
          (component) => component.data.reponse.role === "entrance"
        );

        self.setState({ componentEntrance, reponses: data });
      });
    }
    if (this.state.event.data.free === "false") {
      await ApiService.request(
        {},
        "invoices/settings/" + this.state.event.children_entity_id,
        "get"
      ).then(function (data) {
        self.setState({
          invoiceSetting: data,
        });
      });
    }
    const packagesOptions = this.state.packagesList.map((element) => ({
      label: element.name,
      value: element.id,
    }));

    const tagOptions = this.state.tagsList.map((tag) => ({
      label: tag.tag_name,
      value: tag.id,
    }));

    const followerMaxInvitationOptions =
      this.state.followerMaxInvitationList.map((element) => ({
        number_max_followers: element.max_invitation,
      }));
    await ApiService.request(
      {},
      "guests/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      const totalGuests = [];
      const nonretenus1 = [];
      data.forEach((guest) => {
        if (!guest.contact_id || self.checkIfinvited(data, guest))
          totalGuests.push(guest);
        else nonretenus1.push(guest);
      });
      const followers = data.filter(
        (guest) => guest.is_followers || guest.invited_by
      );
      const guests = data.filter((guest) => {
        if (guest.is_followers) return false;
        return guest.contact_id &&
          self.checkIfinvited(data, guest) &&
          guest.created_by_delegataire &&
          !guest.participate
          ? false
          : true;
      });

      const doublons = [];
      const notFound = [];
      self.state.reponses.forEach((reponse) => {
        if (!notFound.find((id) => id === reponse.guest_id)) {
          notFound.push(reponse.guest_id);
        } else doublons.push(reponse);
      });
      const participants = data
        ? data.filter((guest) =>
          guest.participate ? guest.participate === true : ""
        ).length
        : 0;

      self.setState({
        guestsFiltered: guests,
        guests,
        followers,
        totalGuests: totalGuests,
        participants,
        totalPages: Math.ceil(guests.length / self.state.numberPerPage),
      });
    });

    this.setState({
      event,
      tagOptions,
      packagesOptions,
      followerMaxInvitationOptions,
      isLoading: false,
      guestsChecked: [],
      action: "",
    });

    // Effectuer les filtres après une action utilisateur
    self.filter();
  }

  checkIfinvited(allGuests, guestToCheck) {
    const guests = allGuests.filter(
      (guest) => guest.contact_id === guestToCheck.contact_id
    );
    if (guests.length > 1) {
      if (guestToCheck.invited_by || !guestToCheck.invited_by) {
        return true;
      } else {
        return false;
      }
    } else return true;
  }

  async componentDidMount() {
    const self = this;

    await ApiService.request(
      {},
      "placements/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (placementsList) {
      self.setState({
        placementsList,
      });
    });

    await ApiService.request(
      {},
      "delegataires/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (delegatairesList) {
      const uniqDelegatairesList = makeUniqArrayByKey(
        delegatairesList,
        "user_id"
      );
      self.setState({
        delegatairesList: uniqDelegatairesList.sort(sortByLastName()),
      });
    });

    await ApiService.request(
      {},
      "mailtemplates/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      const templatesOptions = data.map((template) => ({
        label: template.name,
        value: template.id,
      }));

      const dataTemplatesSorted = templatesOptions.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
      );
      self.setState({
        templates: data,
        templatesOptions: dataTemplatesSorted,
      });
    });

    await ApiService.request(
      {},
      "packages/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        packagesList: data,
      });
    });

    await ApiService.request(this.state, "tags", "get").then(function (data) {
      self.setState({ tagsList: data });
    });

    await ApiService.request(
      this.state,
      "evenement_forms/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        form: data.find((form) => form.layout.documentType === "reponse"),
      });
    });

    await ApiService.request(
      this.state,
      "guests/followers/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        followerMaxInvitationList: data,
      });
    });

    this.loadData();

    this.interval = setInterval(() => self.loadData(true), 1200000);
  }

  setGuests(guests) {
    this.setState({ guests, pdfToRender: "" });
  }
  filter() {
    const {
      numberPerPage,
      followers,
      delegataireFilter,
      delegatairesList,
      documentFilter,
      companyFilter,
      followerFilter,
      maxFollowerFilter,
      createdOnCheckinFilter,
      checkRefundRequests,
    } = this.state;

    let guestsFiltered = this.state.guests;

    if (delegataireFilter.length > 0) {
      if (delegataireFilter === "not_invited") {
        guestsFiltered = guestsFiltered.filter(
          (guest) => guest.created_by_delegataire === false
        );
        guestsFiltered = guestsFiltered.filter(
          (guest) => guest.created_by_delegataire === false
        );
      } else if (delegataireFilter === "invited") {
        guestsFiltered = guestsFiltered.filter(
          (guest) => guest.created_by_delegataire === true
        );
      } else {
        guestsFiltered = guestsFiltered.filter((guest) => {
          if (guest.user_id) {
            return delegatairesList.find(
              (delegataire) =>
                delegataire.user_id === guest.user_id &&
                delegataire.user_id === Number(delegataireFilter)
            );
          } else return false;
        });
      }
    }
    if (companyFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        const contact = this.props.contacts.find(
          (contact) => contact.id === guest.contact_id
        );
        if (contact) {
          if (contact.company) {
            if (
              contact.company.name
                .toLowerCase()
                .includes(companyFilter.toLowerCase())
            )
              return guest;
            else return null;
          } else return null;
        } else return null;
      });
    }

    if (this.state.reponseFilter.length > 0) {
      switch (this.state.reponseFilter) {
        case "willJoin":
          guestsFiltered = guestsFiltered.filter(
            (guest) => guest.participate && guest.participate === true
          );
          break;
        case "willNotJoin":
          guestsFiltered = guestsFiltered.filter(
            (guest) =>
              !guest.participate &&
              this.state.reponses.some(
                (reponse) => reponse.guest_id === guest.id
              )
          );
          break;
        case "waiting":
          guestsFiltered = guestsFiltered.filter(
            (guest) =>
              !guest.participate &&
              !this.state.reponses.some(
                (reponse) => reponse.guest_id === guest.id
              )
          );
          break;
        case "pending":
          guestsFiltered = guestsFiltered.filter(
            (guest) => !guest.is_validated
          );
          break;
        default:
          break;
      }
    }

    if (this.state.nameFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        return guest.firstname
          .toLowerCase()
          .includes(this.state.nameFilter.toLowerCase()) ||
          guest.lastname
            .toLowerCase()
            .includes(this.state.nameFilter.toLowerCase()) ||
          followers
            .filter(
              (follower) => follower.invited_by.contact_id === guest.contact_id
            )
            .some(
              (follower) =>
                follower.firstname
                  .toLowerCase()
                  .includes(this.state.nameFilter.toLowerCase()) ||
                follower.lastname
                  .toLowerCase()
                  .includes(this.state.nameFilter.toLowerCase())
            )
          ? guest
          : null;
      });
    }
    if (documentFilter.length > 0) {
      switch (documentFilter) {
        case "hasDocument":
          guestsFiltered = guestsFiltered.filter(
            (guest) => guest.has_documents
          );
          break;
        case "hasntDocument":
          guestsFiltered = guestsFiltered.filter(
            (guest) => !guest.has_documents
          );
          break;
        default:
          break;
      }
    }
    if (this.state.packageFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter(
        (guest) => guest.package_id === Number(this.state.packageFilter)
      );
    }

    if (this.state.tagsFilter.length > 0) {
      console.log("filtrage tags");
      guestsFiltered = guestsFiltered.filter((guest) => {
        const contact = this.props.contacts.find(
          (contact) => contact.id === guest.contact_id
        );

        return contact
          ? contact.tags.some((tag) =>
            this.state.tagsFilter.find(
              (tagFilter) => tagFilter.value === tag.id
            )
          )
            ? guest
            : null
          : null;
      });
    }

    if (followerFilter.length > 0) {
      switch (followerFilter) {
        case "without_follower":
          guestsFiltered = guestsFiltered.filter(
            (guest) => guest.max_invitation <= 0
          );
          break;
        case "with_follower":
          guestsFiltered = guestsFiltered.filter(
            (guest) => guest.max_invitation > 0
          );
          break;
        default:
          break;
      }
    }
    if (this.state.langueFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        return guest.contact_id &&
          this.props.contacts.find(
            (item) =>
              item.id === guest.contact_id &&
              item.languages === this.state.langueFilter
          )
          ? guest
          : null;
      });
    }
    if (maxFollowerFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter(
        (guest) => guest.max_invitation === Number(maxFollowerFilter)
      );
    }
    if (createdOnCheckinFilter) {
      if (createdOnCheckinFilter === "true") {
        guestsFiltered = guestsFiltered.filter(
          (guest) => guest.created_on_checkin === true
        );
      } else if (createdOnCheckinFilter === "false") {
        guestsFiltered = guestsFiltered.filter(
          (guest) => guest.created_on_checkin === false
        );
      }
    }
    if (checkRefundRequests) {
      if (checkRefundRequests === "true") {
        guestsFiltered = guestsFiltered.filter(
          (guest) => guest.checks_to_refund && guest.checks_to_refund.length
        );
      } else if (checkRefundRequests === "false") {
        guestsFiltered = guestsFiltered.filter(
          (guest) => !(guest.checks_to_refund && guest.checks_to_refund.length)
        );
      }
    }

    this.setState({
      guestsFiltered,
      totalPages: Math.ceil(guestsFiltered.length / numberPerPage),
      currentPage: 0,
    });
  }

  generateTableReponse(guest) {
    if (!guest.is_validated && this.props.event.data.free === "false") {
      return "pending";
    } else if (guest.participate) {
      return "willJoin";
    } else if (
      this.state.reponses.some((reponse) => reponse.guest_id === guest.id)
    ) {
      return "willNotJoin";
    } else return "waiting";
  }

  filterGuests(event) {
    const self = this;

    const nameFilter = [event.target.name][0];

    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        const nameFilterlength = self.state.nameFilter.length;

        const dontFilter =
          nameFilter === "nameFilter" &&
          nameFilterlength < 3 &&
          nameFilterlength > 0;

        if (!dontFilter) {
          self.filter();
        }
      }
    );
  }

  async handleChange(event) {
    this.setState({ [event.target.name]: event.target.value, pdfToRender: "" });
    if (event.target.name === "action") {
      switch (event.target.value) {
        case "delete":
          if (
            this.state.guestsChecked.length > 0 &&
            window.confirm("Etes vous sûr de valider cette action ?")
          ) {
            const self = this;
            this.setState({ isLoading: true }, async () => {
              await ApiService.request(
                {
                  guests_ids: this.state.guestsChecked.map((guest) => guest.id),
                },
                "guests/delete/bygroup",
                "delete"
              );

              self.loadData();
            });
          }
          break;
        case "multiPasseports":
          // Keep participated guests
          const participatedGuests = this.state.guestsChecked.filter(
            (guest) =>
              guest.participate &&
              this.props.event.data.passportName &&
              this.props.event.data.passportName.length > 0
          );

          // Keep only guests ids
          const guestsIds = participatedGuests.map((guest) => guest.id);
          console.log(guestsIds);

          try {
            await this.passeportZipGeneration(guestsIds);
          } catch (error) {
            alert("Une erreur s'est produite lors du téléchargement :");
            console.log(
              "Une erreur s'est produite lors du téléchargement :",
              error
            );
          }
          break;
        default:
          break;
      }
    }
  }

  filterByTag(event) {
    const self = this;

    this.setState(
      {
        tagsFilter: event ? event : "",
      },
      () => {
        self.filter();
      }
    );
  }

  async openModalUploadFile(guest) {
    const open = !this.state.openModal;
    if (open) {
      this.setState({ guestModal: guest });
    } else {
      this.setState({ guestModal: "" });
    }
    this.setState({ openModal: open });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  async toggleModal(key, guest) {
    const open = !this.state[key];
    if (open && guest) {
      this.setState({ guestModal: guest });
    } else {
      this.setState({ guestModal: "" });
    }
    this.setState({ [key]: open });
  }

  defaultPackageGuest(guestChecked) {
    if (guestChecked.length === 0) {
      return "default";
    } else if (guestChecked.length === 1) {
      return guestChecked[0].package_id;
    } else {
      const result = guestChecked.every((element) => {
        if (element.package_id === guestChecked[0].package_id) {
          return true;
        }
        return false;
      });
      if (result) {
        return guestChecked[0].package_id;
      } else return "default";
    }
  }

  defaultMaxInvitationGuest(guestChecked) {
    if (guestChecked.length === 0) {
      return 0;
    } else if (guestChecked.length === 1) {
      return guestChecked[0].max_invitation;
    } else {
      const result = guestChecked.every((element) => {
        if (element.max_invitation === guestChecked[0].max_invitation) {
          return true;
        }
        return false;
      });
      if (result) {
        return guestChecked[0].max_invitation;
      } else return 0;
    }
  }

  defaultPackageFollowers(guestChecked) {
    if (guestChecked.length === 0) {
      return "default";
    } else if (guestChecked.length === 1) {
      return guestChecked[0].invitation_package_id;
    } else {
      const result = guestChecked.every((element) => {
        if (
          element.invitation_package_id ===
          guestChecked[0].invitation_package_id
        ) {
          return true;
        }
        return false;
      });
      if (result) {
        return guestChecked[0].invitation_package_id;
      }
      return "default";
    }
  }

  updateOneGuest(guest) {
    const guestsList = this.state.guests;
    const guestIndex = guestsList.findIndex((g) => g.id === guest.id);
    if (guestIndex) {
      guestsList[guestIndex] = guest;
      this.setState({ guests: guestsList });
    }
  }

  sortInvoiceNumbers(invoices) {
    return invoices.sort(
      (
        { invoice_number: invoice_number_a },
        { invoice_number: invoice_number_b }
      ) => {
        if (invoice_number_a === null && invoice_number_b === null) return 0;
        if (invoice_number_a === null) return -1; // or return 1 to place nulls at the end
        if (invoice_number_b === null) return 1; // or return -1 to place nulls at the beginning

        // Extract the numerical part of the invoice number
        const numA = parseInt(
          invoice_number_a.split("-").pop().split("_")[0],
          10
        );
        const numB = parseInt(
          invoice_number_b.split("-").pop().split("_")[0],
          10
        );

        // Compare the numerical parts
        return numA - numB;
      }
    );
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const {
      guests,
      guestsChecked,
      nameFilter,
      tagOptions,
      tagsList,
      companiesOptions,
      packagesOptions,
      packageFilter,
      invitation_package_id,
      action,
      max_invitation,
      packageId,
      packagesList,
      reponses,
      templatesOptions,
      pdfToRender,
      reponseFilter,
      followers,
      form,
      invoicesList,
      templates,
      participants,
      totalGuests,
      totalPages,
      currentPage,
      numberPerPage,
      followersDisplayed,
      componentEntrance,
      delegatairesList,
      delegataireFilter,
      companyFilter,
      langueFilter,
      placementsList,
      openModal,
      guestModal,
      openModalPreview,
      documentFilter,
      maxGuests,
      followerFilter,
      followerMaxInvitationOptions,
      maxFollowerFilter,
      createdOnCheckinFilter,
      checkRefundRequests,
      invoiceSetting,
      isPasseportLoading,
      checkPaymentModal,
      isEventEnded,
    } = this.state;

    /*
  
script vérification enregistrement des guests

    guests.forEach(guest => {
      let sum = 0;
      if (guest.contact_id && guest.participate) {
        const factures = invoicesList.filter(
          item => item.contact_id === guest.contact.id
        );

        let nbaccompagantsdeux = false;
        factures.forEach(facture => {
          if (facture.data.products.length > 1) {
            nbaccompagantsdeux = true;
          }
          sum = sum + facture.data.totalTTC;
        });

        if (sum > 114 && nbaccompagantsdeux) {
          if (
            followers.filter(
              follower => follower.invited_by.contact_id === guest.contact_id
            ).length === 0
          ) {
            console.log(
              "SUM pour " + guest.lastname + " avec l'id " + guest.id + " : ",
              sum
            );
            console.log(
              "Danger : Nombre d'accompagnants : " +
                followers.filter(
                  follower =>
                    follower.invited_by.contact_id === guest.contact_id
                ).length
            );
          }
        }
      }
    });*/

    const guestsFiltered = this.state.guestsFiltered.filter(
      (guest) =>
        !guest.created_on_checkin ||
        guest.created_on_checkin ||
        (guest.user_id && guest.participate)
    );

    const { event, contacts } = this.props;

    const guestscheck = guestsChecked
      .filter((guest) => {
        if (guest.contact_id) {
          const contact = contacts.find((item) => item.id === guest.contact_id);

          if (!contact) {
            return false;
          }
          return contact.active;
        }
        return false;
      })
      .map((guest) => guest.id);
    return (
      <div className="row">
        <div className="col-md-10 offset-1 main-content w-80">
          {!isEventPaymentConfigOk(
            event.data.free,
            invoiceSetting.stripePublicKey,
            invoiceSetting.stripeSecretKey,
            this.props.components
          ) ? (
            <>
              L'évènement est payant, vous devez mettre en place une ou des
              solutions de paiements pour l'Entité de l'évènement.
              <br />
            </>
          ) : packagesList.length === 0 || !form || !componentEntrance ? (
            <div className="row">
              Les éléments suivants n'ont pas tous été crées :
              <br />
              {!componentEntrance ? (
                <>
                  {"Composant d'entrée"}
                  <br />
                </>
              ) : null}
              {packagesList.length === 0 ? (
                <>
                  {"Package"}
                  <br />
                </>
              ) : null}
              {!form ? (
                <>
                  {"Formulaire d'inscription"}
                  <br />
                </>
              ) : null}
            </div>
          ) : (
            <>
              <div className="row stats-and-actions">
                <div className={"col-5"}>
                  <div className="mb-4 row container_number_guests_participant">
                    <div
                      className={"white col-lg-4 metric-panel-guest"}
                      id="event_invites_total"
                    >
                      <div className={"metric-panel-title"}>
                        NOMBRE D'INVITES <br />
                      </div>
                      <div className={"metric-panel-value mt-"}>
                        {totalGuests.length}
                      </div>
                    </div>

                    <div
                      className={"white col-lg-4 metric-panel-participant"}
                      id="event_participants_total"
                    >
                      <div className={"metric-panel-title"}>
                        NOMBRE DE PARTICIPANTS
                      </div>
                      <div className={"metric-panel-value"}>{participants}</div>
                    </div>
                  </div>
                </div>

                {openModal ? (
                  <UploadFileModal
                    open={openModal}
                    setModal={this.openModalUploadFile}
                    guest={guestModal}
                    followers={followers.filter(
                      (follower) =>
                        follower.invited_by.contact_id === guestModal.contact_id
                    )}
                  />
                ) : null}
                {openModalPreview ? (
                  <EmailPreviewModal
                    open={openModalPreview}
                    keyModal="openModalPreview"
                    setModal={() => this.toggleModal("openModalPreview")}
                    guest={guestModal}
                    contact={contacts.find(
                      (c) => c.id === guestModal.contact_id
                    )}
                    templates={templates
                      .filter((temp) => !temp.delegataire)
                      .sort((a, b) => a.id - b.id)}
                    event={event}
                    packages={packagesList}
                  />
                ) : null}
                <div className={"col pt-0 container_guest_buttons row"}>
                  {/* container-btn-export-guest */}
                  <div className="col button_container">
                    <ExportGuests
                      {...this.props}
                      placements={placementsList}
                      delegatairesList={delegatairesList}
                      event={event}
                      form={form}
                      reponses={reponses}
                      guests={totalGuests}
                      followers={followers.filter(
                        (follower) => !follower.contact_id
                      )}
                      packagesList={packagesList}
                      loadData={this.loadData}
                      contacts={contacts}
                      components={this.props.components.filter(
                        (component) =>
                          component.data.componentType !== "map" &&
                          component.data.componentType !== "display"
                      )}
                    />
                  </div>
                  {/*  */}

                  <div className="col button_container">
                    <QuickGuestsEditor
                      eventId={this.props.match.params.eventid}
                      packagesList={packagesList}
                      updateContact={this.updateContact}
                      contacts={contacts}
                    />
                  </div>

                  <div className="col button_container">
                    <GuestsEditor
                      eventId={this.props.match.params.eventid}
                      packagesList={packagesList}
                      tagOptions={tagOptions}
                      tagsList={tagsList}
                      companiesOptions={companiesOptions}
                      loadData={this.loadData}
                      contacts={contacts.filter(
                        (contact) =>
                          !guests.some(
                            (guest) => guest.contact_id === contact.id
                          ) &&
                          !followers.some(
                            (follower) => follower.contact_id === contact.id
                          ) &&
                          contact.active
                      )}
                      packagesOptions={packagesOptions}
                    />
                  </div>
                </div>
              </div>

              <div className={"white filters"}>
                <div className="row">
                  <div className="col-md-3 ">
                    <div className="md-form">
                      <input
                        type="text"
                        value={nameFilter}
                        name="nameFilter"
                        placeholder={"Filtrer par nom"}
                        onChange={this.filterGuests}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <input
                        type="text"
                        value={companyFilter}
                        name="companyFilter"
                        placeholder={"Filtrer par société"}
                        onChange={this.filterGuests}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <select
                        onChange={this.filterGuests}
                        name="langueFilter"
                        className="browser-default custom-select"
                        value={langueFilter}
                      >
                        <option value="">Filtrer par langue</option>

                        {languagesList.map((option, i) => (
                          <option key={i} value={option.code}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <select
                        onChange={this.filterGuests}
                        name="packageFilter"
                        className="browser-default custom-select"
                        value={packageFilter}
                        required
                      >
                        <option value="">Filtrer par package</option>
                        {packagesOptions.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <select
                        onChange={this.filterGuests}
                        name="delegataireFilter"
                        className="browser-default custom-select"
                        value={delegataireFilter}
                        required
                      >
                        <option value="">Filtrer par délégataire</option>
                        <option value="invited">
                          Invités par un délégataire
                        </option>
                        <option value="not_invited">
                          Non invités par un délégataire
                        </option>
                        {delegatairesList.map((delegataire, i) => (
                          <option key={i} value={delegataire.user_id}>
                            {delegataire.user.lastname}{" "}
                            {delegataire.user.firstname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <select
                        onChange={this.filterGuests}
                        name="reponseFilter"
                        className="browser-default custom-select"
                        value={reponseFilter}
                        required
                      >
                        <option value="">Filtrer par réponse</option>

                        {responsesOptions.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <select
                        onChange={this.filterGuests}
                        name="documentFilter"
                        className="browser-default custom-select"
                        value={documentFilter}
                        required
                      >
                        <option value="">Filtrer par document</option>
                        {documentsOptions.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <Select
                        placeholder={"Filtrer par tags"}
                        onChange={this.filterByTag}
                        options={tagOptions}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <select
                        onChange={this.filterGuests}
                        name="followerFilter"
                        className="browser-default custom-select"
                        value={followerFilter}
                      >
                        <option value="">Filtrer : A/S Accompagnant</option>
                        <option value="with_follower">
                          Invités avec le droit d'être accompagné
                        </option>
                        <option value="without_follower">
                          Invités sans le droit d'être accompagné
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <select
                        onChange={this.filterGuests}
                        name="maxFollowerFilter"
                        className="browser-default custom-select"
                        value={maxFollowerFilter}
                      >
                        <option value="">
                          Filtrer par le nombre d'Accompagnant maximum
                        </option>
                        {followerMaxInvitationOptions.map((option, i) => (
                          <option key={i} value={option.number_max_followers}>
                            {option.number_max_followers} accompagnant(s)
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <select
                        onChange={this.filterGuests}
                        name="createdOnCheckinFilter"
                        className="browser-default custom-select"
                        value={createdOnCheckinFilter}
                      >
                        <option value="">Créé au checkin</option>
                        <option key={"1"} value={"true"}>
                          OUI
                        </option>
                        <option key={"2"} value={"false"}>
                          NON
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="md-form">
                      <select
                        onChange={this.filterGuests}
                        name="checkRefundRequests"
                        className="browser-default custom-select"
                        value={checkRefundRequests}
                      >
                        <option value="">Remboursements</option>
                        <option key={"1"} value={"true"}>
                          OUI
                        </option>
                        <option key={"2"} value={"false"}>
                          NON
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"white filters"}>
                <div className="row">
                  <div className="col-md-12 m-0 p-0">
                    <MDBInput
                      className="m-0 p-0"
                      onClick={() => {
                        this.setState({ isLoading: true });
                        this.props.disableMailNotifications();
                      }}
                      id={"disableMailNotifications"}
                      key={"disableMailNotifications"}
                      checked={this.state.event.disable_notifications}
                      label={
                        this.state.event.disable_notifications
                          ? "Ré-activer les emails de notification"
                          : "Désactiver les emails de notification"
                      }
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
              {pdfToRender}
              {isPasseportLoading ? <Loader /> : null}
              <div className={"table-container"}>
                <table className="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          ref={this.checkAllRef}
                          type="checkbox"
                          style={{
                            opacity: 1,
                            pointerEvents: "auto",
                            position: "relative",
                          }}
                          onChange={this.checkAll}
                        />
                      </th>
                      <th scope="col" className="nameColumn">
                        Nom et prénom
                      </th>
                      <th scope="col" className="center-table-item">
                        Langue
                      </th>
                      <th scope="col" className="center-table-item">
                        Société
                      </th>
                      <th scope="col" className="center-table-item">
                        Packages
                      </th>
                      <th scope="col" className="center-table-item">
                        Accompagné
                      </th>
                      <th scope="col" className="center-table-item">
                        Réponse
                      </th>
                      <th scope="col" className="center-table-item">
                        Checkin
                      </th>
                      <th scope="col" className="center-table-item">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {guestsFiltered.map((guest, i) => {
                      if (
                        i < numberPerPage * (currentPage + 1) &&
                        i >= numberPerPage * currentPage
                      ) {
                        const repCondition = reponses.some(
                          (reponse) => reponse.guest_id === Number(guest.id)
                        );

                        const guestResponseStatus = this.generateTableReponse(
                          guest.invited_by ? guest.invited_by : guest
                        );
                        return (
                          <React.Fragment key={i}>
                            <tr
                              style={{
                                backgroundColor: translations.find(
                                  (translation) =>
                                    translation.value === guestResponseStatus
                                ).color,
                              }}
                              key={i}
                            >
                              <td>
                                {!guest.invited_by ? (
                                  <input
                                    type="checkbox"
                                    checked={
                                      guestsChecked.filter(
                                        (guestChecked) =>
                                          guestChecked.id === guest.id
                                      ).length > 0
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        guestsChecked: checkOne(
                                          e,
                                          guest,
                                          guestsChecked
                                        ),
                                        packageId:
                                          this.defaultPackageGuest(
                                            guestsChecked
                                          ),
                                        max_invitation:
                                          this.defaultMaxInvitationGuest(
                                            guestsChecked
                                          ),
                                        invitation_package_id:
                                          this.defaultPackageFollowers(
                                            guestsChecked
                                          ),
                                      })
                                    }
                                    style={{
                                      opacity: 1,
                                      pointerEvents: "auto",
                                    }}
                                  />
                                ) : null}
                              </td>
                              <td className="nameColumn">
                                {guest.firstname + " " + guest.lastname}&nbsp;
                                &nbsp;
                                <Link
                                  style={{ float: "right", opacity: "0.5" }}
                                  target="_blank"
                                  to={
                                    "/admin/contacts/edit/" + guest.contact_id
                                  }
                                >
                                  <FontAwesomeIcon
                                    title={"Editer le contact"}
                                    icon={faEdit}
                                  />
                                </Link>
                                <br />
                                <span className={"nameColumn-email"}>
                                  {guest.user_id
                                    ? delegatairesList.find(
                                      (delegataire) =>
                                        delegataire.user_id === guest.user_id
                                    )
                                      ? "( invité par le délégataire " +
                                      delegatairesList.find(
                                        (delegataire) =>
                                          delegataire.user_id ===
                                          guest.user_id
                                      ).user.firstname +
                                      " " +
                                      delegatairesList.find(
                                        (delegataire) =>
                                          delegataire.user_id ===
                                          guest.user_id
                                      ).user.lastname +
                                      " )"
                                      : "(délégataire inconnu ou supprimé)"
                                    : !guest.invited_by
                                      ? guest.email
                                      : "( invité par " +
                                      guest.invited_by.firstname +
                                      " " +
                                      guest.invited_by.lastname +
                                      ")"}
                                </span>
                              </td>
                              <td className="center-table-item">
                                {contacts.find(
                                  (contact) => contact.id === guest.contact_id
                                )
                                  ? contacts.find(
                                    (contact) =>
                                      contact.id === guest.contact_id
                                  ).languages
                                  : null}
                              </td>
                              <td className="center-table-item">
                                {contacts.find(
                                  (contact) => contact.id === guest.contact_id
                                )
                                  ? contacts.find(
                                    (contact) =>
                                      contact.id === guest.contact_id
                                  ).company
                                    ? contacts.find(
                                      (contact) =>
                                        contact.id === guest.contact_id
                                    ).company.name
                                    : null
                                  : null}
                              </td>

                              <td className="center-table-item">
                                {packagesList.find(
                                  (item) => item.id === guest.package_id
                                )
                                  ? packagesList.find(
                                    (item) => item.id === guest.package_id
                                  ).name
                                  : null}
                                <br />
                                {packagesList.find(
                                  (item) =>
                                    item.id === guest.invitation_package_id
                                )
                                  ? packagesList.find(
                                    (item) =>
                                      item.id === guest.invitation_package_id
                                  ).name
                                  : null}
                              </td>
                              <td className="center-table-item">
                                <Showaccompagne
                                  followers={followers}
                                  guest={guest}
                                />
                                {followers.filter(
                                  (follower) =>
                                    follower.invited_by.contact_id ===
                                    guest.contact_id
                                ).length > 0 && (
                                    <FontAwesomeIcon
                                      onClick={() =>
                                        displayFollowers(guest, this)
                                      }
                                      icon={
                                        followersDisplayed.some(
                                          (item) => item === guest.id
                                        )
                                          ? faChevronCircleDown
                                          : faChevronCircleRight
                                      }
                                    />
                                  )}
                              </td>
                              <td className="center-table-item">
                                {
                                  translations.find(
                                    (translation) =>
                                      translation.value === guestResponseStatus
                                  ).label
                                }
                              </td>

                              <td className="center-table-item">
                                {hasGuestBeenChecked(guest) ? (
                                  <FontAwesomeIcon icon={faCheck} />
                                ) : null}
                              </td>

                              {!guest.invited_by &&
                                !guest.created_on_checkin ? (
                                <td className="center-table-item">
                                  {guest.participate ? (
                                    <>
                                      &nbsp;
                                      <GuestPage
                                        {...this.props}
                                        documentType={"website"}
                                        guest={guest}
                                        event={this.props.event}
                                      />
                                    </>
                                  ) : null}
                                  &nbsp; &nbsp; &nbsp;
                                  {!isEventEnded && (
                                    <>
                                      &nbsp;
                                      <GuestPage
                                        {...this.props}
                                        loadData={this.loadData}
                                        reponses={reponses}
                                        guest={guest}
                                        documentType={"reponse"}
                                        event={this.props.event}
                                      />
                                    </>
                                  )}
                                  &nbsp;
                                  {(repCondition && !guest.is_validated) ||
                                    (guest.checks_to_refund &&
                                      guest.checks_to_refund.length) ? (
                                    <>
                                      &nbsp;
                                      <FontAwesomeIcon
                                        title={"Valider la participation"}
                                        onClick={() =>
                                          this.toggleModal(
                                            "checkPaymentModal",
                                            guest
                                          )
                                        }
                                        icon={faMoneyCheck}
                                        color={
                                          guest.checks_to_refund &&
                                            guest.checks_to_refund.length
                                            ? "orange"
                                            : "#212529"
                                        }
                                      />
                                      &nbsp;
                                      {checkPaymentModal &&
                                        guestModal.id === guest.id && (
                                          <ParticipationValidation
                                            modal={checkPaymentModal}
                                            toggle={this.toggleModal}
                                            loadData={this.loadData}
                                            templates={templates}
                                            code="FR"
                                            invoicesList={invoicesList}
                                            // guest={guest}
                                            guest={guestModal}
                                            updateOneGuest={this.updateOneGuest}
                                            event={this.props.event}
                                            isThereOrderToValidate={
                                              repCondition &&
                                              !guest.is_validated
                                            }
                                          />
                                        )}
                                    </>
                                  ) : null}
                                  {repCondition ? (
                                    <>
                                      {/*  {
                                      console.log(invoicesList.some(
                                        (invoice) =>
                                          invoice.contact_id === 45819
                                      ), "asasas")
                                    } */}
                                      {event.data.free === "false" &&
                                        invoicesList.some(
                                          (invoice) =>
                                            invoice.contact_id ===
                                            guest.contact_id
                                        ) ? (
                                        <>
                                          <InvoicesModal
                                            downLoadInvoice={
                                              this.downLoadInvoice
                                            }
                                            guestId={guest.id}
                                            eventId={event.id}
                                            invoices={this.sortInvoiceNumbers(
                                              invoicesList.filter(
                                                (invoice) =>
                                                  invoice.contact_id ===
                                                  guest.contact_id
                                              )
                                            )}
                                          />
                                          &nbsp;
                                        </>
                                      ) : null}
                                      {guest.participate &&
                                        event.data.passportName &&
                                        event.data.passportName.length > 0 ? (
                                        <>
                                          &nbsp;
                                          <FontAwesomeIcon
                                            title={"Télécharger le passeport"}
                                            onClick={() =>
                                              this.downLoadPassport(
                                                guest,
                                                event
                                              )
                                            }
                                            icon={faIdCard}
                                          />
                                        </>
                                      ) : null}
                                      &nbsp;
                                      <CheckinMessage
                                        guest={guest}
                                        guests={this.state.guests}
                                        setGuests={this.setGuests}
                                      />
                                    </>
                                  ) : null}{" "}
                                  {!isEventEnded &&
                                    (guestResponseStatus === "willNotJoin" || event.data.free === true) && (
                                      <>
                                        &nbsp;
                                        <FontAwesomeIcon
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                "Voulez vous supprimer la réponse de l'invité ?"
                                              )
                                            )
                                              this.deleteGuestResponse(guest);
                                          }}
                                          icon={faEraser}
                                          title={"Supprimer la réponse"}
                                        />
                                      </>
                                    )}
                                  &nbsp; &nbsp; &nbsp;
                                  <FontAwesomeIcon
                                    icon={faPaperclip}
                                    onClick={() =>
                                      this.openModalUploadFile(guest)
                                    }
                                    color={
                                      (guest.has_documents && "#d81b60") ||
                                      "black"
                                    }
                                  />
                                  &nbsp;
                                  {this.state.templates.length === 0 ? (
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      className="iconDisabled"
                                      title="aucun template d'email disponible"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      onClick={() =>
                                        this.toggleModal(
                                          "openModalPreview",
                                          guest
                                        )
                                      }
                                    />
                                  )}
                                  {guest.email &&
                                    guest.contact_id &&
                                    contacts.some(
                                      (contact) =>
                                        contact.active &&
                                        guest.contact_id === contact.id &&
                                        ((contact.mail && contact.mail_enable) ||
                                          (contact.mail_second &&
                                            contact.mail_second_enable))
                                    ) ? (
                                    <span>
                                      &nbsp; &nbsp;
                                      <TransacEmailContainer
                                        templatesOptions={templatesOptions}
                                        guest_ids={[guest.id]}
                                        title={"Renvoyer le mail"}
                                        icon={faAt}
                                        eventId={this.props.event.id}
                                        user_ids={null}
                                      />
                                    </span>
                                  ) : null}
                                  &nbsp; &nbsp; &nbsp;
                                </td>
                              ) : (
                                <td />
                              )}
                            </tr>
                            {followersDisplayed.some(
                              (item) => item === guest.id
                            )
                              ? followers
                                .filter(
                                  (follower) =>
                                    follower.invited_by.contact_id ===
                                    guest.contact_id
                                )
                                .map((follower) => (
                                  <tr
                                    style={{
                                      backgroundColor: "#3E3E3E",
                                      color: "white",
                                    }}
                                  >
                                    <td />
                                    <td>
                                      {follower.firstname +
                                        " " +
                                        follower.lastname}
                                    </td>
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                    <td>
                                      {<CheckinMessage guest={follower} />}
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </React.Fragment>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
              </div>
              <div
                className="row"
                style={{ listStyleType: "none", marginBottom: "270px" }}
              >
                {
                  <Pagination
                    changePage={this.changePage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                  />
                }
              </div>
              {guestsChecked.length > 0 ? (
                <div className="row mr-0 ml-0 actions-container actions-container-fixed">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          {guestsChecked.length + "\n invité"}
                          {guestsChecked.length > 1 ? "s \n" : "\n"}
                          sélectionné
                          {guestsChecked.length > 1 ? "s" : "\n"}
                        </label>
                        <select
                          onChange={this.handleChange}
                          name="action"
                          className="col-sm-12 browser-default custom-select"
                          value={action}
                          required
                        >
                          <option value="" disabled>
                            Sélectionner une action groupée
                          </option>
                          <option value="modify">Modifier</option>
                          <option value="delete">Supprimer</option>
                          <option
                            value="email"
                            disabled={
                              guestsChecked.length >= maxGuests ? true : false
                            }
                          >
                            Envoyer un email{" "}
                            {guestsChecked.length >= maxGuests
                              ? `(max ${maxGuests} invités)`
                              : ""}
                          </option>
                          <option value="checkin">
                            Envoyer un message au checkin des invités
                          </option>
                          <option value="checkin_followers">
                            Envoyer un message au checkin des invités et
                            accompagnants
                          </option>
                          <option value="multiReponses">
                            Répondre au formulaire
                          </option>
                          {event.data.enablePassportForGuest ? (
                            <option value="multiPasseports">
                              Télécharger le passeport
                            </option>
                          ) : null}
                        </select>
                      </div>
                    </div>
                    {action === "modify" ? (
                      <>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Package invité</label>
                            <select
                              onChange={this.handleChange}
                              name="packageId"
                              className="browser-default custom-select"
                              defaultValue={packageId}
                              required
                            >
                              <option value={"default"}>
                                Sélectionnez un package
                              </option>
                              {packagesOptions.map((option, i) => (
                                <option key={i} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <MDBInput
                              label={"Nombre d'invités"}
                              name="max_invitation"
                              value={max_invitation.toString()}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          {max_invitation > 0 && (
                            <div className="form-group">
                              <label>Package accompagnant</label>
                              <select
                                onChange={this.handleChange}
                                name="invitation_package_id"
                                className="browser-default custom-select"
                                value={invitation_package_id}
                                required
                              >
                                <option value="">
                                  Sélectionnez un package
                                </option>
                                {packagesOptions.map((option, i) => (
                                  <option key={i} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          <div className="form-group">
                            <Button
                              className="btn btn-block  pink darken-1"
                              disabled={
                                guestsChecked.length === 0 ||
                                packageId.length === 0 ||
                                max_invitation.length === 0
                              }
                              onClick={this.editGuests}
                            >
                              Appliquer
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="align-self-start">
                      <FontAwesomeIcon
                        title={"Fermer"}
                        icon={faTimes}
                        onClick={() => {
                          if (
                            this.checkAllRef.current &&
                            this.checkAllRef.current.checked
                          )
                            this.checkAllRef.current.checked = false;

                          this.setState({ guestsChecked: [], packageId: "" });
                        }}
                        color="white"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <br />
            </>
          )}
          {action === "multiReponses" ? (
            <GuestPage
              {...this.props}
              isModifiable={false}
              documentType={"reponse"}
              modal={true}
              guest={this.state.guestsChecked[0]}
              guests={this.state.guestsChecked}
              event={this.props.event}
            />
          ) : null}
        </div>
        {action === "email" ? (
          <TransacEmailContainer
            templatesOptions={templatesOptions}
            guest_ids={guestscheck}
            user_ids={null}
            eventId={this.props.event.id}
            modal={true}
          />
        ) : null}
        {action === "checkin" ? (
          <CheckinMessage
            guests={this.state.guests}
            setGuests={this.setGuests}
            guestsChecked={this.state.guestsChecked}
            multiMessage="true"
            modal={true}
            loadData={this.loadData}
          />
        ) : null}
        {action === "checkin_followers" ? (
          <CheckinMessage
            guests={this.state.guests}
            setGuests={this.setGuests}
            guestsChecked={this.state.guestsChecked}
            multiMessage="true"
            followers="true"
            modal={true}
            loadData={this.loadData}
            eventId={this.props.event.id}
          />
        ) : null}
      </div>
    );
  }
}

export default GuestsContainer;

const translations = [
  {
    label: "OUI",
    value: "willJoin",
    color: "#B3E9E9",
  },
  {
    label: "NON",
    value: "willNotJoin",
    color: "#FEC784",
  },
  {
    label: "-",
    value: "waiting",
    color: "transparent",
  },
  {
    label: "EN ATTENTE DE VALIDATION",
    value: "pending",
    color: "#FFF79A",
  },
];