import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { languagesList } from "../../../common/Translations";
import { documentsOptions, responsesOptions } from "../../../services/Utils";

const CampaignFilter = ({
  nameFilter,
  handleNameFilter,
  companyFilter,
  handleCompanyFilter,
  langueFilter,
  handleLangueFilter,
  packageIdFilter,
  handlePackageIdFilter,
  delegataireFilter,
  handleDelegataireFilter,
  reponseFilter,
  handleReponseFilter,
  documentFilter,
  handleDocumentFilter,
  tagOptions,
  handleTagOptions,
  followerFilter,
  handleFollowerFilter,
  maxFollowerFilter,
  handleMaxFollowerFilter,
  packagesOptions,
  delegatairesList,
  followerMaxInvitationOptions,
  validPhoneNumberFilter,
  handleValidPhoneNumberFilter,
}) => {
  return (
    <div className="form-row">
      <div className="form-group col-md-4">
        <input
          type="text"
          value={nameFilter}
          name="nameFilter"
          placeholder={"Rechercher par nom"}
          onChange={handleNameFilter}
          className="form-control"
        />
      </div>

      <div className="form-group col-md-4">
        <input
          type="text"
          value={companyFilter}
          name="companyFilter"
          placeholder={"Rechercher par société"}
          onChange={handleCompanyFilter}
          className="form-control"
        />
      </div>
      <div className="form-group  col-md-4">
        <Select
          placeholder={"Rechercher par tags"}
          onChange={handleTagOptions}
          options={tagOptions}
          isMulti={true}
        />
      </div>
      <div className="form-group col-md-4">
        <select
          onChange={handleLangueFilter}
          name="langueFilter"
          className="browser-default custom-select"
          value={langueFilter}
        >
          <option value="">Filtrer par langue</option>

          {languagesList.map((option, i) => (
            <option key={i} value={option.code}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group col-md-4">
        <select
          onChange={handlePackageIdFilter}
          name="packageIdFilter"
          className="browser-default custom-select"
          value={packageIdFilter}
        >
          <option value="">Filtrer par package</option>
          {packagesOptions.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group col-md-4">
        <select
          onChange={handleDelegataireFilter}
          name="delegataireFilter"
          className="browser-default custom-select"
          defaultValue="not_invited"
          value={delegataireFilter}
        >
          <option value="">Filtrer par délégataire</option>
          <option value="invited">Invités par un délégataire</option>
          <option value="not_invited">Non invités par un délégataire</option>
          {delegatairesList.map((delegataire, i) => (
            <option key={i} value={delegataire.user_id}>
              {delegataire.user.lastname} {delegataire.user.firstname}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group col-md-4">
        <select
          onChange={handleReponseFilter}
          name="reponseFilter"
          className="browser-default custom-select"
          value={reponseFilter}
        >
          <option value="">Filtrer par réponse</option>
          {responsesOptions.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group col-md-4">
        <select
          onChange={handleDocumentFilter}
          name="documentFilter"
          className="browser-default custom-select"
          value={documentFilter}
        >
          <option value="">Filtrer par document</option>
          {documentsOptions.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group col-md-4">
        <select
          onChange={handleFollowerFilter}
          name="followerFilter"
          className="browser-default custom-select"
          value={followerFilter}
        >
          <option value="">Filtrer : A/S Accompagnant</option>
          <option value="with_follower">
            Invités avec le droit d'être accompagné
          </option>
          <option value="without_follower">
            Invités sans le droit d'être accompagné
          </option>
        </select>
      </div>

      <div className="form-group col-md-4">
        <select
          onChange={handleMaxFollowerFilter}
          name="maxFollowerFilter"
          className="browser-default custom-select"
          value={maxFollowerFilter}
        >
          <option value="">Filtrer par le nombre d'Accompagnant maximum</option>
          {followerMaxInvitationOptions.map((option, i) => (
            <option key={i} value={option.number_max_followers}>
              {option.number_max_followers} accompagnant(s)
            </option>
          ))}
        </select>
      </div>

      {validPhoneNumberFilter !== undefined && (
        <div className="form-group col-md-4">
          <select
            onChange={handleValidPhoneNumberFilter}
            name="validPhoneNumberFilter"
            className="browser-default custom-select"
            value={validPhoneNumberFilter}
          >
            <option value="">Filtrer par numéros</option>
            <option value="valid_numbers">Numéros valides</option>
            <option value="invalid_numbers">Numéros invalides</option>
          </select>
        </div>
      )}
    </div>
  );
};

CampaignFilter.prototype = {
  nameFilter: PropTypes.string.isRequired,
  handleNameFilter: PropTypes.func.isRequired,
  companyFilter: PropTypes.string.isRequired,
  handleCompanyFilter: PropTypes.func.isRequired,
  langueFilter: PropTypes.string.isRequired,
  handleLangueFilter: PropTypes.func.isRequired,
  packageIdFilter: PropTypes.string.isRequired,
  validPhoneNumberFilter: PropTypes.string,
  handleValidPhoneNumberFilter: PropTypes.func,
  handlePackageIdFilter: PropTypes.func.isRequired,
  delegataireFilter: PropTypes.string.isRequired,
  handleDelegataireFilter: PropTypes.func.isRequired,
  reponseFilter: PropTypes.string.isRequired,
  handleReponseFilter: PropTypes.func.isRequired,
  documentFilter: PropTypes.string.isRequired,
  handleDocumentFilter: PropTypes.func.isRequired,
  tagOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })
  ).isRequired,
  handleTagOptions: PropTypes.func.isRequired,
  followerFilter: PropTypes.string.isRequired,
  handlefollowerFilter: PropTypes.func.isRequired,
  maxFollowerFilter: PropTypes.string.isRequired,
  handleMaxFollowerFilter: PropTypes.func.isRequired,
  packagesOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })
  ).isRequired,
  delegatairesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  followerMaxInvitationOptions: PropTypes.array.isRequired,
};

export { CampaignFilter };
