import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, Box } from "@mui/material";
import GuestsList from "./GuestsList";
import Header from "./components/Header";
import FilterGuests from "./components/FilterGuests";

import { getCheckinData, isGuestChecked } from "./utils";
import ApiService from "../../services/ApiService";
import { accentedArrayOfString } from "../../services/Utils";

function Emargement(props) {
  const [guests, setGuests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [query, setQuery] = useState("");
  const [queryDelayed, setQueryDelayed] = useState("");
  const [queryLoading, setQueryLoading] = useState(false);
  const [joinFilter, setJoinFilter] = useState("all");
  const [placements, setPlacements] = useState([]);
  const [formId, setFormId] = useState(null);
  const [eventResponses, setEventResponses] = useState([]);
  const [components, setComponents] = useState([]);
  const [checkinPoint, setCheckinPoint] = useState(null);
  const isFirstRender = useRef(true);
  const { eventId, checkinPointId } = props.match.params;

  const eventRef = useRef(event);
  const formIdRef = useRef(formId);
  useEffect(() => {
    eventRef.current = event;
    formIdRef.current = formId;
  }, [event, formId]); // Update ref whenever event changes

  const searchGuest = (myGuests = guests) => {
    const formatedQuery = query.toLowerCase().trim();
    if (formatedQuery !== "") {
      return myGuests.filter((item) =>
        accentedArrayOfString(formatedQuery).some(
          (string) =>
            item.firstname.toLowerCase().trim().includes(string) ||
            item.lastname.toLowerCase().trim().includes(string) ||
            (item.contact &&
              item.contact.company &&
              item.contact.company.name &&
              item.contact.company.name.toLowerCase().trim().includes(string))
        )
      );
    } else {
      return myGuests.filter(
        (item) =>
          item.firstname.toLowerCase().trim().includes(formatedQuery) ||
          item.lastname.toLowerCase().trim().includes(formatedQuery) ||
          (item.contact &&
            item.contact.company &&
            item.contact.company.name &&
            item.contact.company.name
              .toLowerCase()
              .trim()
              .includes(formatedQuery))
      );
    }
  };

  //TODO: UPDATE
  const participantFilter = () => {
    switch (joinFilter) {
      case "checkedin":
        return guests.filter((guest) => {
          const checkedGuest = isGuestChecked(guest, checkinPointId);
          return checkedGuest;
        });
      case "pending":
        return guests.filter((guest) => {
          const checkedGuest = isGuestChecked(guest, checkinPointId);
          return !checkedGuest;
        });
      default:
        return guests;
    }
  };

  const filterGuests = () => {
    const resultGuest = searchGuest(participantFilter());

    const resultGuestOrdered = resultGuest.sort(function (a, b) {
      if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
        return -1;
      }
      if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
        return 1;
      }
      // Si les noms de famille sont identiques, comparer par prénom
      if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) {
        return -1;
      }
      if (a.firstname.toLowerCase() > b.firstname.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    return resultGuestOrdered;
  };

  const refreshGuests = (currentEvent, currentFormId) => {
    getCheckinData(
      eventId,
      currentEvent,
      checkinPointId,
      currentFormId,
      isFirstRender,
      setEvent,
      setGuests,
      setPlacements,
      setIsLoading,
      setFormId,
      setCheckinPoint
    );
    console.log("update 1");
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setQuery(queryDelayed);
      setQueryLoading(false);
    }, 1000);

    return () => clearTimeout(timeOutId);
  }, [queryDelayed]);

  useEffect(() => {
    // const socket = io("http://websocket.dev.kanguroo-event.fr");

    let interval;

    //! WHY USE SETTIMEOUT? Breaking the Call Stack 🤷‍♂️ Ensuring Asynchronous Execution 🤷‍♂️
    setTimeout(() => {
      refreshGuests(eventRef.current, formIdRef.current);
      interval = setInterval(() => {
        refreshGuests(eventRef.current, formIdRef.current);
      }, 15000);
    }, 0);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    (async () => {
      const checkinData = await ApiService.awaitRequest(
        {},
        "evenements/details/" + eventId,
        "get"
      );
      if (checkinData && checkinData.component_responses) {
        setEventResponses(checkinData.component_responses);
      }
      if (checkinData && checkinData.components) {
        setComponents(checkinData.components);
      }
    })();
  }, []);

  if (isLoading)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress sx={{ color: "#D23466" }} />
      </Box>
    );

  return (
    <Box>
      <Box
        sx={{ width: "100%", bgcolor: "pink", position: "sticky", top: "0px" }}
      >
        <Header
          checkinPointName={checkinPoint ? checkinPoint.name : ""}
          eventId={event.id}
          event={event}
          checkinPointId={checkinPointId}
          guests={guests}
          setGuests={setGuests}
          isGuestChecked={isGuestChecked}
          formId={formId}
          mode="REGISTRATION"
          setEvent={setEvent}
          setPlacements={setPlacements}
          setIsLoading={setIsLoading}
          setFormId={setFormId}
          setCheckinPoint={setCheckinPoint}
          isFirstRender={isFirstRender}
        />
        <FilterGuests
          query={queryDelayed}
          setQuery={setQueryDelayed}
          setQueryLoading={setQueryLoading}
          joinFilter={joinFilter}
          setJoinFilter={setJoinFilter}
          guests={guests}
          isGuestChecked={isGuestChecked}
          checkinPointId={checkinPointId}
        />
      </Box>

      <Box sx={{ overflowY: "auto" }}>
        {queryLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "13rem",
            }}
          >
            <CircularProgress sx={{ color: "#D23466" }} />
          </Box>
        ) : (
          <GuestsList
            guests={filterGuests()}
            setGuests={setGuests}
            checkinPointId={checkinPointId}
            placements={placements}
            eventId={eventId}
            formId={formId}
            eventResponses={eventResponses}
            components={components}
          />
        )}
      </Box>
    </Box>
  );
}

export default Emargement;
