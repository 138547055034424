import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, Box } from "@mui/material";
import { QrReader } from "@blackbox-vision/react-qr-reader";
import Header from "./components/Header";
import {
  getCheckinData,
  getGuestNbrOfCheck,
  isGuestChecked,
  updateGuestCheckinData,
} from "./utils";
import DetailDialog from "./dialogs/DetailDialog";
import ApiService from "../../services/ApiService";
import Loader from "../../common/Loader";

function Scan(props) {
  const [guests, setGuests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [placements, setPlacements] = useState([]);
  const [formId, setFormId] = useState(null);
  const [eventResponses, setEventResponses] = useState([]);
  const [components, setComponents] = useState([]);
  const [checkinPoint, setCheckinPoint] = useState(null);
  const isFirstRender = useRef(true);
  const { eventId, checkinPointId } = props.match.params;

  const [isLoadingCheckinData, setIsLoadingCheckinData] = useState(true);
  const [audio, setAudio] = useState(new Audio());
  const [scanData, setScanData] = useState(null);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [startScan, setStartScan] = useState(true);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [scanError, setScanError] = useState(null);
  const [reponse, setReponse] = useState("");

  const buttonRef = useRef(null);
  const eventRef = useRef(event);

  useEffect(() => {
    eventRef.current = event;
  }, [event]); // Update ref whenever event changes

  const loadAudio = () => {
    const _audio = new Audio();
    _audio.src = process.env.PUBLIC_URL + "/bip.mp3";
    _audio.load();
    _audio.addEventListener("canplaythrough", () => {
      setAudio(_audio);
    });
  };

  const playAudio = () => {
    audio.play().catch((e) => {
      console.log(e, "error");
    });
  };

  const handleDetailsDialogOpen = (status) => {
    if (!status) setScanError(false);
    setOpenDetailDialog(status);
    if (status) {
      setStartScan(false);
    } else {
      setStartScan(true);
    }
  };

  //TODO: UPDATE
  const onScan = async (guest) => {
    if (guest) {
      setScanError(false);
      updateGuestCheckinData(
        guest,
        true,
        checkinPointId,
        setGuests,
        setSelectedGuest,
        handleDetailsDialogOpen
      );
    } else {
      setScanError(true);
      handleDetailsDialogOpen(true);
    }
  };

  //TODO: UPDATE
  const handleScan = (data, error) => {
    if (!!error) {
      console.log("Erreur lors du scan", { error });
      // onScan(null);
    }
    if (!!data) {
      try {
        if (data && data !== scanData) {
          const guestInfo = data.text.split(";");
          const guest = guests.find(
            (guest) => guest.id === Number(guestInfo[0])
          );
          const audio = new Audio();
          audio.src = process.env.PUBLIC_URL + "/bip.mp3";

          if (guest) {
            const guestHasCheckedin = isGuestChecked(guest, checkinPointId);
            const guestNbrOfCheck = getGuestNbrOfCheck(guest, checkinPointId);
            setSelectedGuest(guest);
            const myreps = eventResponses.find(
              (rep) =>
                rep.guest_id === guest.id && rep.evenement_form_id === formId
            );
            setReponse(myreps);
            if (guestHasCheckedin && guestNbrOfCheck > 0) {
              window.alert(
                "Cet invité a déjà été scanné " + guestNbrOfCheck + " fois."
              );
              setScanData(data);
              audio.play();
              onScan(guest);
            } else {
              setScanData(() => {
                audio.play();
                onScan(guest);
                return data;
              });
            }
          } else {
            onScan(null);
          }
        }
      } catch (error) {
        console.log("Erreur lors du scan 2", { error });
        onScan(null);
      }
    }
  };

  const refreshGuests = (currentEvent) => {
    getCheckinData(
      eventId,
      currentEvent,
      checkinPointId,
      formId,
      isFirstRender,
      setEvent,
      setGuests,
      setPlacements,
      setIsLoading,
      setFormId,
      setCheckinPoint
    );
  };

  useEffect(() => {
    loadAudio();
    (async () => {
      const { eventId } = props.match.params;
      const checkinData = await ApiService.awaitRequest(
        {},
        "evenements/details/" + eventId,
        "get"
      );
      if (checkinData && checkinData.component_responses) {
        setEventResponses(checkinData.component_responses);
      }
      if (checkinData && checkinData.components) {
        setComponents(checkinData.components);
      }
      if (checkinData) {
        setIsLoadingCheckinData(false);
      }
    })();
  }, []);

  useEffect(() => {
    let interval;

    setTimeout(() => {
      refreshGuests(eventRef.current);
      interval = setInterval(() => {
        refreshGuests(eventRef.current);
      }, 15000);
    }, 0);

    return () => clearInterval(interval);
  }, []);

  if (isLoading || isLoadingCheckinData)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress sx={{ color: "#D23466" }} />
      </Box>
    );

  if (isLoadingCheckinData) {
    return <Loader />;
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <DetailDialog
        handleOpen={handleDetailsDialogOpen}
        open={openDetailDialog}
        guest={selectedGuest}
        scanError={scanError}
        placements={placements}
        reponse={reponse}
        components={components}
      />

      <Box
        sx={{ width: "100%", bgcolor: "pink", position: "sticky", top: "0px" }}
      >
        <Header
          checkinPointName={checkinPoint ? checkinPoint.name : ""}
          eventId={event.id}
          event={event}
          checkinPointId={checkinPointId}
          formId={formId}
          guests={guests}
          setGuests={setGuests}
          isGuestChecked={isGuestChecked}
          mode="SCAN"
          setEvent={setEvent}
          setPlacements={setPlacements}
          setIsLoading={setIsLoading}
          setFormId={setFormId}
          setCheckinPoint={setCheckinPoint}
          isFirstRender={isFirstRender}
        />
      </Box>
      <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
        <Box
          sx={{
            height: "100%",
            opacity: "1 !important",
            backgroundColor: "#d81b60",
            backgroundImage: 'url("/bg-checkin.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {startScan && (
            <QrReader
              scanDelay={1000}
              onResult={handleScan}
              style={{ width: "100%" }}
              videoStyle={{ width: "100%", height: "auto" }}
              constraints={{ facingMode: "environment" }}
            />
          )}
          <button ref={buttonRef} onClick={playAudio} hidden />
        </Box>
      </Box>
    </Box>
  );
}

export default Scan;
