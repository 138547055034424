import React from "react";
import ApiService from "../../../services/ApiService";
import { MDBInput, Button } from "mdbreact";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";
import FileUpload from "../../../common/FileUpload";
import { capitalizeFirstLetter, uploadPicture } from "../../../services/Utils";
import { roles, delegataireId, adminId } from "../../../services/Utils";
import { Image, CloudinaryContext } from "cloudinary-react";
import { Editor } from "@tinymce/tinymce-react";
import Creatable from "react-select/lib/Creatable";
import "../../../index.css";

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleEntity = this.toggleEntity.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.removeIcon = this.removeIcon.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);

    this._isMounted = false;

    this.state = {
      user: {
        passwordConfirmation: "",
        role: adminId,
        lastname: "",
        firstname: "",
        email: "",
        is_owner: false,
        job: "",
        department: "",
        mobile: "",
        phone: "",
        address: "",
        address_more: "",
        zip_code: "",
        city: "",
        country: "",
        password: "",
        new_password_required: false,
        logo: null,
        inviting_person: "",
        contact_informations: "",
        categories: [],
      },
      isLoading: false,
      entities: [],
      entitiesChecked: [],
      categoriesOptions: [],
    };
  }

  onFileUpload(id) {
    this.setState({
      user: {
        ...this.state.user,
        logo: id,
      },
    });
  }

  removeIcon() {
    this.setState({
      user: {
        ...this.state.user,
        logo: null,
      },
    });
  }

  toggleEntity(entityId) {
    const { entitiesChecked } = this.state;
    if (entitiesChecked.includes(entityId)) {
      this.setState({
        entitiesChecked: entitiesChecked.filter(
          (entity) => entity !== entityId
        ),
      });
    } else {
      entitiesChecked.push(entityId);
      this.setState({ entitiesChecked });
    }
  }

  async componentDidMount() {
    const self = this;
    this._isMounted = true;

    try {
      ApiService.request({}, "children_entities", "get").then(function (data) {
        self._isMounted && self.setState({ entities: data });
      });

      if (this.props.match.params.id) {
        self._isMounted && self.setState({ isLoading: true });
        await ApiService.request(
          this.state,
          "users/" + this.props.match.params.id,
          "get"
        ).then(function (data) {
          self._isMounted &&
            self.setState({
              user: { ...self.state.user, ...data },
              entitiesChecked: data.children_entities.map(
                (entity) => entity.id
              ),
            });
        });
      }

      const categoriesOptions = await ApiService.request(
        this.state,
        "users/categoriesoptions",
        "get"
      );

      if (categoriesOptions) {
        self._isMounted && self.setState({ categoriesOptions });
      }
    } catch (error) {
      toast.error("Une erreur s'est produite");
    } finally {
      self._isMounted && self.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async handleChangeCategory(categories) {
    this.setState({
      user: { ...this.state.user, categories: categories },
    });
  }

  handleChange(e, label) {
    const name = label ? label : e.target.name;
    const value = label ? e : e.target.value;

    this.setState({
      user: { ...this.state.user, [name]: value },
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    let method = this.props.match.params.id ? "put" : "post";
    let id = this.props.match.params.id ? this.props.match.params.id : "";
    const self = this;

    this.setState({ isLoading: true });

    try {
      const user = this.state.user;

      user.categories = user.categories
        ? user.categories.map((ctg) => ({
          label: capitalizeFirstLetter(ctg.label),
          value: ctg.value.toLowerCase(),
        }))
        : [];

      await ApiService.request({ ...user }, "users/" + id, method).then(
        (data) => {
          if (self.state.entitiesChecked.length > 0) {
            ApiService.request(
              {
                role: "user",
                children_entity_ids: self.state.entitiesChecked,
              },
              "users/" + data.id + "/addchildrenentity/",
              "post"
            ).then(function () {
              toast.success("Utilisateur sauvegardé", {
                autoClose: 3000,
              });

              self.setState({ isLoading: false }, () => {
                self.props.history.push("/admin/users");
              });
            });
          } else {
            toast.success("Utilisateur sauvegardé", {
              autoClose: 3000,
            });

            self.setState({ isLoading: false }, () => {
              self.props.history.push("/admin/users");
            });
          }
        }
      );
    } catch (e) {
      console.log("🚀 ~ UserForm ~ handleSubmit ~ e:", e);
      if (
        e.response &&
        e.response.data &&
        e.response.data.e
      ) {
        switch (e.response.data.e) {
          case "This user has guests in an active event, it cannot be updated":
            toast.error(
              "Impossible de changer le rôle de l'utilisateur, il possède des guests dans un évènement actif."
            );
            break;
          case "This email is already in use":
            toast.error(
              "L'email saisi est associé à un autre utilisateur."
            );
            break;
          default:
            toast.error("Erreur lors de l'enregistrement de l'utilisateur");
            break;
        }
      } else {
        toast.error("Erreur lors de l'enregistrement de l'utilisateur");
      }
      self.setState({ isLoading: false });
    }

    e.preventDefault();
  }

  render() {
    const { user, isLoading, entities, entitiesChecked, categoriesOptions } =
      this.state;

    const entitiesOptions = entities.map((entity) => ({
      label: entity.data.name,
      value: entity.id,
    }));

    if (isLoading) {
      return <Loader />;
    }
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <form onSubmit={this.handleSubmit} className="form">
            <h4>Informations générales</h4>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nom *</label>
                <input
                  type="text"
                  name="lastname"
                  className="form-control"
                  value={user.lastname}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Prénom *</label>
                <input
                  type="text"
                  name="firstname"
                  className="form-control"
                  value={user.firstname}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Entité</label>
                {entitiesOptions.map((option, index) => (
                  <MDBInput
                    onClick={() => this.toggleEntity(option.value)}
                    id={`${option.value}`}
                    key={`user-form-entity-${index}`}
                    label={option.label}
                    filled
                    checked={entitiesChecked.some(
                      (entity) => entity === option.value
                    )}
                    type="checkbox"
                  />
                ))}
              </div>
              <div className="form-group col-md-6">
                <label>Rôle</label>
                <select
                  onChange={this.handleChange}
                  name="role"
                  className="form-control browser-default custom-select"
                  value={user.role}
                >
                  {roles.map((option, index) => (
                    <option key={`user-form-role-${index}`} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {user.role === delegataireId ? (
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Catégorie</label>
                  <div className="user-formpage-category-input">
                    <Creatable
                      placeholder={"Sélectionnez ou créez les catégories"}
                      isMulti
                      value={this.state.user.categories || []}
                      onChange={this.handleChangeCategory}
                      options={categoriesOptions}
                    />
                  </div>
                </div>

                <div className="form-group col-md-6">
                  <label>Logo</label>
                  <FileUpload
                    buttonLabel={"Charger un logo"}
                    onFileUpload={this.onFileUpload}
                  />

                  {user.logo ? (
                    <Button
                      className="btn pink white-text darken-1 btn-lg "
                      onClick={this.removeIcon}
                    >
                      Supprimer le logo
                    </Button>
                  ) : null}
                  {user.logo ? (
                    <div className="col-md-3">
                      <CloudinaryContext cloudName="kanguroo-event">
                        <Image className="img-thumbnail" publicId={user.logo} />
                      </CloudinaryContext>
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-md-6">
                  <label>Informations de contact</label>
                  <Editor
                    value={user.contact_informations}
                    init={{
                      images_upload_handler: function (
                        blobInfo,
                        success,
                        failure
                      ) {
                        uploadPicture(blobInfo, success, failure);
                      },

                      document_base_url: window.location.origin,
                      relative_urls: false,
                      remove_script_host: false,
                      menubar: false,
                      plugins: "link code lists",
                      toolbar:
                        "' undo redo blocks fontsize | fontfamily | bold italic removeformat subscript superscript code | forecolor underline backcolor numlist bullist | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent '",
                    }}
                    onEditorChange={(e) =>
                      this.handleChange(e, "contact_informations")
                    }
                  />
                </div>

                <div className="form-group col-md-6">
                  <label>Personne invitante</label>
                  <Editor
                    value={user.inviting_person}
                    init={{
                      images_upload_handler: function (
                        blobInfo,
                        success,
                        failure
                      ) {
                        uploadPicture(blobInfo, success, failure);
                      },

                      document_base_url: window.location.origin,
                      relative_urls: false,
                      remove_script_host: false,
                      menubar: false,
                      plugins: "link code lists",
                      toolbar:
                        "' undo redo blocks fontsize | fontfamily | bold italic removeformat subscript superscript code | forecolor underline backcolor numlist bullist | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent '",
                    }}
                    onEditorChange={(e) =>
                      this.handleChange(e, "inviting_person")
                    }
                  />
                </div>
              </div>
            ) : null}

            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Fonction</label>
                <input
                  type="text"
                  name="job"
                  className="form-control"
                  value={user.job}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Service</label>
                <input
                  type="text"
                  name="department"
                  className="form-control"
                  value={user.department || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <h4>Adresse</h4>

            <div className="form-row">
              <div className="form-group col-md-8">
                <label>Adresse 1</label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  value={user.address}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label>Code postal</label>
                <input
                  type="text"
                  name="zip_code"
                  className="form-control"
                  value={user.zip_code}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-8">
                <label>Ville</label>
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  value={user.city}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Mot de passe *</label>
                <input
                  minLength={user.password ? 5 : 0}
                  type="text"
                  name="password"
                  className="form-control"
                  value={user.password}
                  onChange={this.handleChange}
                  required={!user.id}
                  placeholder="****************"
                />
              </div>
              <div className="form-group col-md-6">
                <label>Confirmation du mot de passe *</label>
                <input
                  type="text"
                  name="passwordConfirmation"
                  className="form-control"
                  value={user.passwordConfirmation}
                  onChange={this.handleChange}
                  required={user.password.length > 0}
                />
              </div>

              <div className="form-group col-md-6">
                <label>Email (*)</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={user.email}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Mobile</label>
                <input
                  type="text"
                  name="mobile"
                  className="form-control"
                  value={user.mobile}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Téléphone</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  value={user.phone || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={
                user.password.length > 0 &&
                user.password !== user.passwordConfirmation
              }
              className="btn pink darken-1 float-right white-text"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default UserForm;
