import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

export const checkIfinvited = (allGuests, guestToCheck) => {
  const guests = allGuests.filter(
    (guest) => guest.contact_id === guestToCheck.contact_id
  );
  if (guests.length > 1) {
    if (guestToCheck.invited_by) {
      return true;
    } else return false;
  } else return true;
};

export const isValidNumber = (number) => {
  try {
    const phoneNumber = parsePhoneNumber(number);
    if (phoneNumber)
      return isValidPhoneNumber(phoneNumber.number.toString())
        ? phoneNumber.number.toString()
        : null;
    return null;
  } catch (error) {
    return null;
  }
};

export function filter({
  guests,
  nameFilter,
  companyFilter,
  tagsFilter,
  numberPerPage,
  packageIdFilter,
  delegataireFilter,
  delegatairesList,
  reponseFilter,
  reponses,
  documentFilter,
  followerFilter,
  maxFollowerFilter,
  contacts,
  langueFilter,
  upadteTotalPages,
  upadteCurrentPages,
  upadteGuestsFiltered,
}) {
  let guestsFiltered = guests;
  console.log("🚀 ~ guestsFiltered:", guestsFiltered);

  if (nameFilter.length > 0) {
    guestsFiltered = guestsFiltered.filter((guest) => {
      return guest.firstname.toLowerCase().includes(nameFilter.toLowerCase()) ||
        guest.lastname.toLowerCase().includes(nameFilter.toLowerCase())
        ? guest
        : null;
    });
  }

  if (companyFilter.length > 0) {
    guestsFiltered = guestsFiltered.filter((guest) => {
      const contact = contacts.find(
        (contact) => contact.id === guest.contact_id
      );
      if (contact) {
        if (contact.company) {
          if (
            contact.company.name
              .toLowerCase()
              .includes(companyFilter.toLowerCase())
          )
            return guest;
          else return null;
        } else return null;
      } else return null;
    });
  }

  if (packageIdFilter.length > 0) {
    guestsFiltered = guestsFiltered.filter(
      (guest) => guest.package_id === Number(packageIdFilter)
    );
  }

  if (delegataireFilter.length > 0) {
    if (delegataireFilter === "not_invited") {
      guestsFiltered = guestsFiltered.filter(
        (guest) => guest.created_by_delegataire === false
      );
    } else if (delegataireFilter === "invited") {
      guestsFiltered = guestsFiltered.filter(
        (guest) => guest.created_by_delegataire === true
      );
    } else {
      guestsFiltered = guestsFiltered.filter((guest) => {
        if (guest.user_id) {
          return delegatairesList.find(
            (delegataire) =>
              delegataire.user_id === guest.user_id &&
              delegataire.user_id === Number(delegataireFilter)
          );
        } else return false;
      });
    }
  }

  if (reponseFilter.length > 0) {
    switch (reponseFilter) {
      case "willJoin":
        guestsFiltered = guestsFiltered.filter((guest) => guest.participate);
        break;
      case "willNotJoin":
        guestsFiltered = guestsFiltered.filter(
          (guest) =>
            !guest.participate &&
            reponses.some((reponse) => reponse.guest_id === guest.id)
        );
        break;
      case "waiting":
        guestsFiltered = guestsFiltered.filter(
          (guest) =>
            !guest.participate &&
            !reponses.some((reponse) => reponse.guest_id === guest.id)
        );
        break;
      case "pending":
        guestsFiltered = guestsFiltered.filter((guest) => !guest.is_validated);
        break;
      default:
        break;
    }
  }
  if (documentFilter.length > 0) {
    switch (documentFilter) {
      case "hasDocument":
        guestsFiltered = guestsFiltered.filter((guest) => guest.has_documents);
        break;
      case "hasntDocument":
        guestsFiltered = guestsFiltered.filter((guest) => !guest.has_documents);
        break;
      default:
        break;
    }
  }
  if (tagsFilter.length > 0) {
    guestsFiltered = guestsFiltered.filter((guest) => {
      const contact = contacts.find(
        (contact) => contact.id === guest.contact_id
      );
      if (contact) {
        if (
          contact.tags.some((tag) =>
            tagsFilter.find((tagFilter) => tagFilter.value === tag.id)
          )
        )
          return guest;
        else return null;
      } else return null;
    });
  }
  if (followerFilter.length > 0) {
    switch (followerFilter) {
      case "without_follower":
        guestsFiltered = guestsFiltered.filter(
          (guest) => guest.max_invitation <= 0
        );
        break;
      case "with_follower":
        guestsFiltered = guestsFiltered.filter(
          (guest) => guest.max_invitation > 0
        );
        break;
      default:
        break;
    }
  }
  if (langueFilter.length > 0) {
    guestsFiltered = guestsFiltered.filter((guest) => {
      return guest.contact_id &&
        contacts.find(
          (item) =>
            item.id === guest.contact_id && item.languages === langueFilter
        )
        ? guest
        : null;
    });
  }
  if (maxFollowerFilter.length > 0) {
    guestsFiltered = guestsFiltered.filter(
      (guest) => guest.max_invitation === Number(maxFollowerFilter)
    );
  }

  upadteTotalPages(Math.ceil(guestsFiltered.length / numberPerPage));
  upadteCurrentPages(0);
  upadteGuestsFiltered(guestsFiltered);
}

export const getPercentage = (nbr, total) => {
  if (nbr <= 0 || total <= 0) return "0";
  const percentage = (nbr * 100) / total;
  return percentage.toFixed(2);
};
